import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['file', 'fileNameLabel', 'imagePreview']

  file_field_changed(event) {
    let file = this.fileTarget.files[0]
    let label = this.fileNameLabelTarget
    let image = this.imagePreviewTarget

    if (image.querySelector("img") == null) {
      image.innerHTML = `<img src='' class='${image.dataset.classes}'>`
    }

    let reader = new FileReader();

    reader.onload = function(e) {
      image.children[0].setAttribute("src", e.target.result)
    }

    label.textContent = file.name
    reader.readAsDataURL(file);
  }
}
