import { Controller } from "stimulus"

// Fontawesome
// import brands from '@fortawesome/fontawesome-free-brands';
// import fa from '@fortawesome/fontawesome';
// import faSearch from '@fortawesome/fontawesome-free-solid/faSearch'
// import faMapMarkerAlt from '@fortawesome/fontawesome-free-solid/faMapMarkerAlt'
// import faEnvelope from '@fortawesome/fontawesome-free-solid/faEnvelope'
// import faGlobe from '@fortawesome/fontawesome-free-solid/faGlobe'
// import faCheck from '@fortawesome/fontawesome-free-solid/faCheck'
// import faExclamation from '@fortawesome/fontawesome-free-solid/faExclamation'
// import faUpload from '@fortawesome/fontawesome-free-solid/faUpload'

export default class extends Controller {
  connect() {
    //   fa.library.add(faSearch)
    //   fa.library.add(faMapMarkerAlt)
    //   fa.library.add(faEnvelope)
    //   fa.library.add(faGlobe)
    //   fa.library.add(faCheck)
    //   fa.library.add(faExclamation)
    //   fa.library.add(faUpload)

    //   // Reload font awesome
    //   fa.dom.i2svg();
		this.fonts
  }

  get fonts() {
    var WebFont = require('webfontloader')
    WebFont.load({
      google: { families: ['Nunito Sans:200,300,400,700'], async: true }
    });
  }
}
