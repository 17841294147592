import { Controller } from "stimulus"

require('trix/dist/trix.css');
require('trix/dist/trix.js');

export default class extends Controller {
  static targets = []

  connect() {
  }

}
