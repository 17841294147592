import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['fields', 'form']

  connect() {
  }

  delete(event) {
    if (confirm(event.target.dataset.confirmText)) {
      event.target.nextElementSibling.value = "true";
      event.target.parentElement.style.display = "none";
    }
    event.preventDefault();
  }

  add(event) {
    let time, regexp, fields
    time = new Date().getTime()
    regexp = new RegExp(this.fieldsTarget.attributes["data-id"].value, 'g')
    fields = this.fieldsTarget.attributes["data-fields"].value.replace(regexp, time)
    this.fieldsTarget.insertAdjacentHTML('beforebegin', fields)
    event.preventDefault();
  }
}
