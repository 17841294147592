import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input', 'form', 'submit', 'results']

  change() {
    var timeout = null;
    var controller = this;

    if (this.query.length > 1) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(function(event) {
        // #TODO Find a way to avoid the hidden submit button hack
        // GitHub workaround: https://github.com/stimulusjs/stimulus/issues/92
        // Medium post on how to do it differently:https://medium.com/parallel-thinking/stimulus-rails-remote-forms-ca5b3e2f02ed
        // This doesn't work with remote: true
        // controller.formTarget.submit();

        controller.submitTarget.click();
      }, 300);
    } else {
      controller.resultsTarget.innerHTML = "";
    }
  }

  get query() {
    return this.inputTarget.value
  }

  get form() {
    return this.formTarget
  }

  get submit() {
    return this.submitTarget
  }
}
