import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (window.location.hash.length > 1) {
      let element = document.querySelector(window.location.hash);
      console.debug(element);
      setTimeout(function() {
        element.classList.add("publication--highlighted")
      }, 100);
    }
  }
}
